import React, { useCallback } from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { businessSettingsPath, clientBusinessSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { Button } from '@src/components/ui_v2/buttons';

const CompleteSetupButton = ({ businessId }: { businessId: TID }) => {
  const { refetch: fetchBusinessServicesInfo } = useGetBusinessServicesInfo(businessId, {
    enabled: false,
  });

  const handleClick = useCallback(async () => {
    if (window.Docyt.currentAdvisor.isBookgptBusinessUser()) {
      backboneNavigateToUrl(businessSettingsPath(businessId));
    } else {
      const { data: info } = await fetchBusinessServicesInfo();
      if (info && info.accountingFirmBusinessId) {
        backboneNavigateToUrl(clientBusinessSettingsPath(businessId, info.accountingFirmBusinessId));
      }
    }
  }, [fetchBusinessServicesInfo, businessId]);

  return (
    <Button size="compact" variant="outline" onClick={ handleClick }>Complete Setup</Button>
  );
};

export default CompleteSetupButton;
