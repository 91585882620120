/* eslint-disable import/order */
import React, { memo, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import isEmpty from "lodash/isEmpty";
import toastr from "@lib/toastr";
import { Button } from "@src/components/ui_v2/buttons";

import {
  chatChannelCreationMessage,
  FAQ,
  welcomeMessage,
} from "@src/components/ai_chatbot/constants";
import styles from "@src/components/ai_chatbot/styles.module.scss";
import useChatProvider from "@src/components/ai_chatbot/hooks/use_chat_provider";
import { ChatMessageStatus } from "@src/components/ai_chatbot/types";
import {
  activeThreadIdAtom,
  firstMessageAtom,
} from "@src/components/ai_chatbot/atoms";

import ChatInput from "@src/components/ai_chatbot/components/chat_input";
import ChatMessageItem from "@src/components/ai_chatbot/components/chat_message_item";
import useSendbirdHelper from "@src/components/ai_chatbot/hooks/use_sendbird_helper";

const WelcomeView = () => {
  const { sdk } = useSendbirdHelper();
  const { startNewThread } = useChatProvider();
  const activeThreadId = useRecoilValue(activeThreadIdAtom);
  const [firstMessage, setFirstMessage] = useRecoilState(firstMessageAtom);
  const [loader, setLoader] = useState(false);
  const buttonClass = classNames(styles["chat-btn"], "chat-thread-btn");
  const [isLoading, setIsLoading] = useState(false);

  const handleMessageSend = useCallback(
    async (message: string) => {
      const messageSignature = crypto.randomUUID();
      setLoader(true);
      try {
        // Create a new channel with user + bot
        await startNewThread(message, messageSignature);
      } catch (error) {
        console.error("[WelcomeView] handleMessageSend error:", error);
        toastr.error(
          chatChannelCreationMessage.errorSendingMessage.message,
          chatChannelCreationMessage.errorSendingMessage.title
        );
      }
    },
    [startNewThread, setLoader]
  );

  const handleFAQClick = useCallback(
    (message: string) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleMessageSend(message);
      },
    [handleMessageSend]
  );

  useEffect(() => {
    // clear it when activeThreadId is set
    return () => {
      setFirstMessage({ message: "", messageSignature: "" });
    };
  }, [activeThreadId, setFirstMessage]);

  useEffect(() => {
    if (isEmpty(sdk)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [sdk, setFirstMessage]);

  if (isLoading) {
    return (
      <div className={styles["chat-top-section"]}>
        <div className={styles["chat-no-message"]}>
          <div className={styles["no-message-content"]}>
            <div className={styles["spinner"]} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles["chat-top-section"]}>
        {!firstMessage.message ? (
          <div className={styles["chat-no-message"]}>
            <div className={styles["no-message-content"]}>
              <h2 className={styles["title-text"]}>
                <strong>{welcomeMessage.title}</strong>
                {welcomeMessage.message}
              </h2>
            </div>
            <div className={styles["quick-chat-buttons"]}>
              {FAQ.map((item) => (
                <Button
                  key={item.message}
                  className={buttonClass}
                  variant="link"
                  onClick={handleFAQClick(item.message)}
                >
                  {item.summary}
                </Button>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles["chat-message-scroll-outer"]}>
            <div className={styles["chat-message-scroll-inner"]}>
              <ChatMessageItem
                key={firstMessage.messageSignature}
                question={firstMessage.message}
                id=""
                answer=""
                channelAnswerMessageId={0}
                channelQuestionMessageId={0}
                chatMessageStatus={ChatMessageStatus.QuestionSentToSendbird}
                messageSignature={firstMessage.messageSignature}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles["chat-bottom-section"]}>
        <ChatInput loader={loader} handleMessageSend={handleMessageSend} />
      </div>
    </>
  );
};

export default memo(WelcomeView);
