import { memo } from "react";
import { useRecoilValue } from "recoil";
import {
  activeThreadIdAtom,
  threadLoadingStatesAtom,
} from "@src/components/ai_chatbot/atoms";
import { IChatThreadSchema } from "@src/components/ai_chatbot/types";
import styles from "@src/components/ai_chatbot/styles.module.scss";
import StartChatThread from "@src/components/ai_chatbot/components/start_chat_thread";

interface IChatListItemProps {
  thread: IChatThreadSchema;
  prefixIcon?: React.ReactElement;
}

export const ChatListItem = memo(
  ({ thread, prefixIcon }: IChatListItemProps) => {
    const activeThreadId = useRecoilValue(activeThreadIdAtom);
    const loadingStates = useRecoilValue(threadLoadingStatesAtom);

    const isLoading = loadingStates[thread.id as string] === "loading" || false;

    return (
      <div className={styles["chat-list-item"]}>
        <div className={styles["chat-list-item-content"]}>
          <StartChatThread
            buttonText={thread.summary}
            channelId={thread?.channelId}
            isActive={thread.id === activeThreadId && thread.id !== ""}
            prefixIcon={prefixIcon}
            threadId={thread.id}
          />
          {isLoading && <div className={styles["spinner"]} />}
        </div>
      </div>
    );
  }
);
