import React, { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import toastr from "@lib/toastr";
import { Button } from "@src/components/ui_v2/buttons";
import { AiChatExternalIcon } from "@src/components/utils/img_icons";
import {
  activeChannelAtom,
  activeThreadIdAtom,
  firstMessageAtom,
} from "@src/components/ai_chatbot/atoms";
import { useChatMessageHandler } from "@src/components/ai_chatbot/hooks/use_chat_message_handler";
import useChatProvider from "@src/components/ai_chatbot/hooks/use_chat_provider";
import { chatChannelCreationMessage } from "@src/components/ai_chatbot/constants";
import { FAQ } from "@src/components/ai_chatbot/constants";
import styles from "@src/components/ai_chatbot/styles.module.scss";

const FAQSection = () => {
  const { startNewThread } = useChatProvider();
  const { handleMessageSend } = useChatMessageHandler();
  const activeChannel = useRecoilValue(activeChannelAtom);
  const activeThreadId = useRecoilValue(activeThreadIdAtom);
  const setFirstMessage = useSetRecoilState(firstMessageAtom);

  const handleFAQClick = useCallback(
    (message: string) => async () => {
      if (!activeThreadId) {
        const messageSignature = crypto.randomUUID();
        setFirstMessage({ message, messageSignature });
        try {
          // Create a new channel with user + bot
          await startNewThread(message, messageSignature);
        } catch (error) {
          toastr.error(
            chatChannelCreationMessage.errorSendingMessage.message,
            chatChannelCreationMessage.errorSendingMessage.title
          );
        }
      } else {
        // In ChatView, just send the message
        if (activeChannel) {
          handleMessageSend(message);
        }
      }
    },
    [
      activeChannel,
      activeThreadId,
      handleMessageSend,
      setFirstMessage,
      startNewThread,
    ]
  );

  const faqButtons = useMemo(
    () => (
      <div>
        {FAQ.map((item) => (
          <Button
            key={item.summary}
            className={styles["chat-btn"]}
            prefixIcon={<AiChatExternalIcon />}
            variant="link"
            onClick={handleFAQClick(item.message)}
          >
            {item.summary}
          </Button>
        ))}
      </div>
    ),
    [handleFAQClick]
  ); // Only re-create when handleFAQClick changes

  return (
    <div className={styles["chat-btn-section"]}>
      <div className={styles["chat-list-header"]}>
        <h4 className={styles["chat-list-heading"]}>Frequently Asked</h4>
      </div>
      {faqButtons}
    </div>
  );
};

export default FAQSection;
