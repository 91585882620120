import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import AddBusinessPopup from '../components/add_business_popup';

interface IAddBusinessModalProps extends IUseModalProps {
  className?: string;
}

const AddBusinessModal = ({
  isOpen,
  onCancel,
  onDone,
  className,
}: IAddBusinessModalProps): JSX.Element => {
  return (
    <AddBusinessPopup
      className={ className }
      isOpen={ isOpen }
      onCancel={ onCancel }
      onDone={ onDone }
    />
  );
};

const useAddBusinessModal = makeUseModal(AddBusinessModal);

export default useAddBusinessModal;
