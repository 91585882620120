import React, { useCallback } from 'react';

import { IBusiness } from '@src/types/businesses';
import { openQBOConnectWindow } from '@src/utils/qbo_connect';

import Button from '@src/components/ui_v2/buttons/button';
import RightArrow from '@src/components/utils/icomoon/right_arrow';

import styles from '../styles.module.scss';

const ConnectLedgerForm = ({ business }: { business: IBusiness }) => {
  const handleConnect = useCallback(() => {
    openQBOConnectWindow(business.id);
  }, [business.id]);

  return (
    <div className={ styles['onboarding-form'] }>
      <div className={ styles['onboarding-body'] }>
        <div className="display-flex p-b-20 font-16">
          Docyt needs to connect with your QuickBooks Accountant account to:
        </div>
        <ul className={ styles['qbo-connect-list'] }>
          <li>
            <RightArrow fontSize={ 10 } />
            <p>Instantly audit your clients P&L and Balance Sheets</p>
          </li>
          <li>
            <RightArrow fontSize={ 10 } />
            <p>Ensure accurate and error-free financial reports</p>
          </li>
          <li>
            <RightArrow fontSize={ 10 } />
            <p>Speed up your book closing process</p>
          </li>
        </ul>
        <div className="display-flex justify-content-center">
          <Button variant="success" onClick={ handleConnect }>Connect QuickBooks</Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConnectLedgerForm);
