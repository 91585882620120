import { IBusiness } from '@src/types/businesses';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, notAuthorizedApiRequest } from './helpers';

interface ICreateUserAccountParams {
  user: {
    userName: string;
    password: string;
    passwordConfirmation: string;
    onboardingType: string;
  };
  emailAccessToken: string;
}

interface ISignInParams {
  email: string;
  password: string;
  clientId: string;
  clientSecret: string;
  deviceUuid: string | null;
  deviceName: string;
  grantType: string;
}

interface ISignInResponse {
  accessToken: string;
  refreshToken: string;
}

const validateEmail = (email: string): Promise<{ success: boolean }> => {
  return notAuthorizedApiRequest({
    method: 'GET',
    url: '/api/v1/users/validate_email',
    params: underscoreKeys({ email }),
  }).then((data) => camelizeKeys(data) as { success: boolean });
};

const verifyEmail = (email: string): Promise<{ success: boolean; emailAccessToken: string }> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications',
    data: underscoreKeys({ email }),
  }).then((data) => camelizeKeys(data) as { success: boolean; emailAccessToken: string });
};

const confirmEmail = (
  emailAccessToken: string,
  accessCode: string
): Promise<{ success: boolean }> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications/confirm_email',
    data: underscoreKeys({ emailAccessToken, accessCode }),
  }).then((data) => camelizeKeys(data) as { success: boolean });
};

const resendEmail = (emailAccessToken: string): Promise<void> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications/resend_email',
    data: underscoreKeys({ emailAccessToken }),
  });
};

const createUserAccount = (params: ICreateUserAccountParams): Promise<void> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/users',
    data: underscoreKeys(params),
  });
};

const signIn = (params: ISignInParams): Promise<ISignInResponse> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/auth/api/v1/oauth/token',
    data: underscoreKeys(params),
  }).then((data) => camelizeKeys(data) as ISignInResponse);
};

const getCurrentTenant = (): Promise<{ logUrl: string }> => {
  return apiGet('/api/v1/tenants/current').then(
    (data) => camelizeKeys(data.tenant) as { logUrl: string }
  );
};

const getCurrentAdvisor = (): Promise<any> => {
  return apiGet('/api/web/v1/advisor/current_advisor').then((data) => data.advisor);
};

const getDefaultAccountingFirm = (): Promise<IBusiness> => {
  return apiGet('/api/v1/businesses/default_accounting_firm.json').then(
    (data) => camelizeKeys(data.business) as IBusiness
  );
};

export {
  ICreateUserAccountParams,
  ISignInParams,
  ISignInResponse,
  validateEmail,
  verifyEmail,
  confirmEmail,
  resendEmail,
  createUserAccount,
  signIn,
  getCurrentTenant,
  getCurrentAdvisor,
  getDefaultAccountingFirm,
};
