import React, { useMemo } from 'react';

import { TBooksGptOnboardingState, TFirmSuiteOnboardingState } from '@src/types/businesses';
import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { loaderTooltipTextBasedOnStatus } from '@src/components/operational_dashboard/helper/constants';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

interface IProgressActionLoaderProps {
  status: TFirmSuiteOnboardingState | TBooksGptOnboardingState | AuditScanDetails['status'];
}

const ProgressActionLoader = ({ status }: IProgressActionLoaderProps): JSX.Element | null => {
  const loaderTooltipText = useMemo(() => {
    return loaderTooltipTextBasedOnStatus[status];
  }, [status]);

  return (
    <HoverTooltipWrapper
      content={ loaderTooltipText ?? null }
    >
      <ProgressActivityLoader size="large" />
    </HoverTooltipWrapper>
  );
};

export default ProgressActionLoader;
