import { IUndepositedRevenueProps } from '@src/types/common_operational_dashboard';

import { IDashboardData, IListItem, NumberBoxValue, TSetupStatus } from '../types';

import { IBusinessOwnerConfigFormType } from '.';

const hasAnyTrueValue = (obj: any): boolean => {
  if (typeof obj !== 'object' || obj === null) return false;
  return Object.values(obj).some((value) => {
    if (typeof value === 'object') {
      return hasAnyTrueValue(value);
    }
    return value === true;
  });
};

type MappingObject = { [key: string]: string };

const mapObjectKeys = <T extends object>(
  schema: T,
  mapping: MappingObject,
): string[] => {
  const mapKeys = (obj: any, map: MappingObject): string[] => {
    return Object.keys(obj)
      .filter((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          return hasAnyTrueValue(value);
        }
        return value === true;
      })
      .map((key) => map[key as keyof typeof map] ?? '');
  };
  const filteredData = mapKeys(schema, mapping);
  return filteredData;
};

const filterTooltipData = (data: IListItem[], schema: any) => {
  return (
    data?.filter((item: IListItem) => {
      return schema?.[item?.key as keyof IBusinessOwnerConfigFormType];
    }) ?? []
  );
};

const hasActivePreference = (
  preferenceKey: keyof IBusinessOwnerConfigFormType | string,
  preferences?: IBusinessOwnerConfigFormType,
): boolean => {
  if (preferences) {
    const preference =
      preferences[preferenceKey as keyof IBusinessOwnerConfigFormType];
    return hasAnyTrueValue(preference);
  }
  return false;
};

const isSetupStatusComplete = (setupStatus?: TSetupStatus) => setupStatus === 'COMPLETE_SETUP';
const isInSetupStatus = (setupStatus?: TSetupStatus) => setupStatus === 'IN_SETUP';

const progressUndepositedRevenueData = (
  undepositedRevenue: IUndepositedRevenueProps,
) => {
  return [
    {
      value: undepositedRevenue?.undepositedRevenueAmount?.[0] ?? 0,
      color: '#8a2be2',
    },
    {
      value: undepositedRevenue?.undepositedRevenueAmount?.[1] ?? 0,
      color: '',
    },
  ];
};

const getNumberBoxValue = (val?: number): NumberBoxValue => {
  if (typeof val === 'number') {
    return {
      type:  'number',
      value: val,
    };
  }
  return {
    type: 'empty',
  };
};

/**
 * Checks if the audit scan polling is enabled for the given dashboard data.
 * Polling is enabled if the business ID is present, the firm suite onboarding state is 'import_ledger_completed',
 * and the setup is completed.
 *
 * @param {IDashboardData} columnData - The dashboard data to check.
 * @returns {boolean} - Returns true if polling is enabled, otherwise false.
 */
const isAuditScanPollingEnabled = (columnData: IDashboardData): boolean => {
  if (columnData.businessId && columnData.firmSuiteOnboardingState === 'import_ledger_completed' && columnData.isSetupCompleted) {
    return true;
  }
  return false;
};

export {
  hasActivePreference,
  progressUndepositedRevenueData,
  isInSetupStatus,
  getNumberBoxValue,
  hasAnyTrueValue,
  mapObjectKeys,
  filterTooltipData,
  isSetupStatusComplete,
  isAuditScanPollingEnabled,
};
