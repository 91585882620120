import { useCallback, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PushTriggerOption } from "@sendbird/chat";

import toastr from "@lib/toastr";
import { useCreateChatThread } from "@src/hooks/queries/ai_chat/ai_chatbot";

import { chatChannelCreationMessage } from "@src/components/ai_chatbot/constants";
import { getDefaultDataProperty } from "@src/components/ai_chatbot/utils";
import {
  activeChannelAtom,
  activeChannelIdAtom,
  activeThreadIdAtom,
  chatMessageLoaderAtom,
  conversationLoaderAtom,
  firstMessageAtom,
  historyItemsAtom,
  threadsAtom,
} from "@src/components/ai_chatbot/atoms";
import useSendbirdHelper from "@src/components/ai_chatbot/hooks/use_sendbird_helper";

const useChatProvider = () => {
  const { context, sdk } = useSendbirdHelper();
  const { mutateAsync: createChatThread } = useCreateChatThread();

  const setHistoryItems = useSetRecoilState(historyItemsAtom);
  const [threads, setThreads] = useRecoilState(threadsAtom);

  const [activeThreadId, setActiveThreadId] =
    useRecoilState(activeThreadIdAtom);
  const setActiveChannelId = useSetRecoilState(activeChannelIdAtom);
  const setActiveChannel = useSetRecoilState(activeChannelAtom);

  const setFirstMessage = useSetRecoilState(firstMessageAtom);
  const [conversationLoader, setConversationLoader] = useRecoilState(
    conversationLoaderAtom
  );
  const setChatMessageLoader = useSetRecoilState(chatMessageLoaderAtom);

  const startNewThread = useCallback(
    async (message: string, messageSignature: string = crypto.randomUUID()) => {
      if (!message) {
        return;
      }

      try {
        setChatMessageLoader((prev) => [...prev, messageSignature]);
        setFirstMessage({ message, messageSignature });

        // Create a new channel with user + bot
        const channel = await sdk.groupChannel.createChannel({
          invitedUserIds: [
            context.config.userId,
            window.configData.ai_chatbot.sendbird_bot_id,
          ],
          name: window.configData.ai_chatbot.sendbird_bot_name,
          isDistinct: false,
          coverUrl: "",
        });

        channel.setMyPushTriggerOption(PushTriggerOption.OFF);

        setActiveChannel(channel);
        setActiveChannelId(channel.url);

        // create chat thread
        const chatThread = await createChatThread({
          userId: context.config.userId,
          channelId: channel.url,
          message: message,
          summary: message,
          providerId: "Sendbird",
        });

        setHistoryItems((prev) => ({
          ...prev,
          data: [chatThread, ...prev.data],
        }));

        const threadId = chatThread.id as string;
        setActiveThreadId(threadId);

        setThreads((prevThreads) => {
          const currentData = prevThreads?.data || {};
          const prevMessages = Array.isArray(currentData[threadId])
            ? currentData[threadId]
            : [];

          return {
            ...prevThreads,
            data: {
              ...currentData,
              [threadId]: [
                {
                  id: "",
                  channelQuestionMessageId: 0,
                  question: message,
                  answer: "",
                  channelAnswerMessageId: 0,
                  messageSignature,
                },
                ...prevMessages,
              ],
            },
          };
        });

        const data = getDefaultDataProperty(threadId, messageSignature);

        await channel?.sendUserMessage({ message, data });
      } catch (err) {
        console.error("error starting new thread:", err);
        toastr.error(
          chatChannelCreationMessage.errorCreating.message,
          chatChannelCreationMessage.errorCreating.title
        );
        setChatMessageLoader((prev) =>
          prev.filter((item) => item !== messageSignature)
        );
      }
    },
    [
      sdk,
      context.config,
      activeThreadId,
      setActiveChannel,
      setActiveChannelId,
      setHistoryItems,
      setActiveThreadId,
    ]
  );

  return useMemo(
    () => ({
      startNewThread,
      conversationLoader,
      setConversationLoader,
    }),
    [startNewThread, conversationLoader, setConversationLoader]
  );
};

export default useChatProvider;
