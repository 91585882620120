import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from './components/nav_bar';

import styles from './styles.module.scss';
import useSendbirdHelper from './hooks/use_sendbird_helper';

const AiChatBotMainView = () => {
  const { sendbirdInstance } = useSendbirdHelper();

  useEffect(() => {
    return () => {
      if (!!sendbirdInstance) {
        sendbirdInstance.removeAllChannelHandlers();
      }
    };
  }, [sendbirdInstance]);

  return (
      <div className={styles['chat-main-container']}>
        <NavBar />
        <Outlet />
      </div>
  );
};

export default memo(AiChatBotMainView);
