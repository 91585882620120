import { UseMutationOptions, useMutation, useQuery } from "react-query";

import { QueryKey } from "@src/constants/query_keys";
import {
  getChatThreadById,
  getListOfThreads,
  getMessageHistory,
  createSessionToken,
  createChatThread,
} from "@src/requests/ai_chat/ai_chatbot";
import { TUUID } from "@src/types/common";

import {
  IMessageSchema,
  IChatThreadSchema,
  ICreateSessionTokenResponse,
  IThreadListParams,
  IGlobalThreadListResponse,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse,
  ICreateChatThreadParams,
} from "@src/components/ai_chatbot/types";

import { createUseGetInfiniteCollectionBasedOnLastTimestamp } from "@src/hooks/queries/infinite_collection_queries";

const useGetListOfThreads = createUseGetInfiniteCollectionBasedOnLastTimestamp<
  IChatThreadSchema,
  IThreadListParams,
  IGlobalThreadListResponse
>({
  queryKey: QueryKey.AiChatThreads,
  request: getListOfThreads,
});

const useGetThreadById = (chatThreadId: TUUID) => {
  return useQuery<IChatThreadSchema, Error>(
    [QueryKey.AiChatThreadById, chatThreadId],
    () => getChatThreadById(chatThreadId),
    {
      enabled: !!chatThreadId,
    }
  );
};

const useGetMessageHistory = createUseGetInfiniteCollectionBasedOnLastTimestamp<
  IMessageSchema,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse
>({
  queryKey: QueryKey.AIChatThreadMessages,
  request: getMessageHistory,
});

const useCreateSessionToken = () => {
  return useQuery<ICreateSessionTokenResponse, Error>({
    queryFn: () => createSessionToken(),
  });
};

const useCreateChatThread = (
  options?: UseMutationOptions<
    IChatThreadSchema,
    Error,
    ICreateChatThreadParams
  >
) => {
  return useMutation<IChatThreadSchema, Error, ICreateChatThreadParams>(
    createChatThread,
    options
  );
};

export {
  useGetListOfThreads,
  useGetMessageHistory,
  useGetThreadById,
  useCreateSessionToken,
  useCreateChatThread,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse,
  IGlobalThreadListResponse,
  ICreateChatThreadParams,
};
