import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AiChatBotMainView from './ai_chat_bot_main_view';
import ChatBody from './components/chat_body';

const AiChatBotRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/ai_chatbot" element={<AiChatBotMainView />}>
        {/* If no threadId is provided, we show the main page */}
        <Route index element={<ChatBody />} />
        {/* Catch all other paths and redirect to index */}
        <Route path="*" element={<Navigate to="/ai_chatbot" replace />} />
      </Route>
    </Routes>
  );
};

export default AiChatBotRouter;
