import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import { useForm, useController } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useStandardCategories } from '@src/hooks/useStandardCategories';
import { createBusiness } from '@src/requests/businesses';

import BusinessField from '@src/components/common/form_fields/business_field';
import Form from '@src/components/ui/form';

import { addClientValidation, IAddClientFormValues } from './schema';

interface IAddBusinessFormProps {
  formId?: string;
  setIsLoading?: (isLoading: boolean) => void;
  onSuccess?: () => void;
  onSubmit?: (data: IAddBusinessFormValues) => void;
}

interface IAddBusinessFormValues {
  standardCategory: number;
  legalName: string;
  displayName: string;
  serviceProviderBusinessId: number;
}

const AddClientForm: React.FC<IAddBusinessFormProps> = ({
  formId = 'addClientForm',
  onSubmit,
  onSuccess,
  setIsLoading,
}) => {
  const { categories, isLoading } = useStandardCategories();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddClientFormValues>({
    resolver:      yupResolver(addClientValidation),
    mode:          'onBlur', // Validate on blur for better UX
    defaultValues: {
      serviceProviderBusinessId: Number(localStorage.getItem('accounting_firm_business_id')) || undefined,
    },
  });

  const businessIdController = useController({
    name: 'serviceProviderBusinessId',
    control,
  });

  const handleFormSubmit = async (data: IAddBusinessFormValues) => {
    if (!data.serviceProviderBusinessId) {
      toastr.error('Please select a Service Provider Business', 'Validation Error');
      if (setIsLoading) {
        setIsLoading(false);
      }
      return;
    }

    if (setIsLoading) {
      setIsLoading(true);
    }

    try {
      await createBusiness({
        business: {
          addBusinessAdvisor:        true,
          standardCategoryId:        data.standardCategory,
          name:                      data.legalName,
          displayName:               data.displayName,
          serviceProviderBusinessId: data.serviceProviderBusinessId,
        },
      });

      toastr.success(`${data.displayName} added successfully`, 'Add Client');

      if (onSubmit) {
        onSubmit(data);
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Failed to create Client. Please try again.';
      toastr.error(errorMessage, 'Add Client Failed');
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  const categoryOptions = [
    { value: '', name: 'Select Industry', id: '' },
    ...categories.map((cat) => ({
      value: String(cat.id),
      name:  cat.name,
      id:    cat.id,
    })),
  ];

  return (
    <Form id={ formId } onSubmit={ handleSubmit(handleFormSubmit) }>
      {!businessIdController.field.value && (
        <BusinessField
          error={ errors.serviceProviderBusinessId?.message }
          label="Service Provider Business"
          { ...omit(businessIdController.field, 'ref') }
        />
      )}
      <Form.SelectField
        error={ errors.standardCategory?.message }
        label="Select Industry:"
        options={ categoryOptions }
        placeholder={ isLoading ? 'Loading categories...' : 'Select Category' }
        { ...register('standardCategory') }
      />

      <Form.TextField
        error={ errors.legalName?.message }
        label="Legal Name:"
        placeholder="Enter Legal Name"
        { ...register('legalName') }
      />

      <Form.TextField
        error={ errors.displayName?.message }
        label="Display Name:"
        placeholder="Enter Display Name"
        { ...register('displayName') }
      />
    </Form>
  );
};

export default AddClientForm;
