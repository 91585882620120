import React, { memo, forwardRef } from "react";

import { IChatThreadSchema } from "@src/components/ai_chatbot/types";
import { ChatListItem } from "@src/components/ai_chatbot/components/chat_list_item";
import styles from "@src/components/ai_chatbot/styles.module.scss";

interface IChatListSectionsProps {
  header: string;
  list: IChatThreadSchema[];
  prefixIcon?: React.ReactElement;
  infiniteScrollRef?: React.ForwardedRef<HTMLDivElement>;
  isLoading?: boolean;
}

const ChatListSections = forwardRef<HTMLDivElement, IChatListSectionsProps>(
  (props, ref) => {
    const { header, list, prefixIcon, infiniteScrollRef, isLoading } = props;

    return (
      <div ref={ref} className={styles["chat-btn-section"]}>
        <div className={styles["chat-list-header"]}>
          <h4 className={styles["chat-list-heading"]}>{header}</h4>
          {isLoading && <div className={styles["spinner"]} />}
        </div>
        <div ref={infiniteScrollRef}>
          {list?.map((item) => (
            <ChatListItem
              key={`${item.id}${item.summary}`}
              thread={item}
              prefixIcon={prefixIcon}
            />
          ))}
        </div>
      </div>
    );
  }
);

ChatListSections.displayName = "ChatListSections";

export default memo(ChatListSections);
