import React, { memo, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import Markdown from "markdown-to-jsx";

import { SpinnerIcon } from "@src/components/utils/fa_icons";
import { AiChatLogoIcon } from "@src/components/utils/img_icons";

import { chatMessageLoaderAtom } from "@src/components/ai_chatbot/atoms";
import { IMessageSchema } from "@src/components/ai_chatbot/types";
import styles from "@src/components/ai_chatbot/styles.module.scss";

interface IChatMessageItemProps extends IMessageSchema {}

const ChatMessageItem = ({
  question,
  answer,
  channelAnswerMessageId,
  messageSignature,
}: IChatMessageItemProps) => {
  const chatMessageLoader = useRecoilValue(chatMessageLoaderAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open((event.currentTarget as HTMLAnchorElement).href, "_blank");
  };

  useEffect(() => {
    setIsLoading(chatMessageLoader.includes(messageSignature as string));
  }, [chatMessageLoader, messageSignature]);

  return (
    <div className={styles["chat-message"]}>
      <div className={styles["chat-message-question"]}>{question}</div>
      <div className={styles["chat-message-answer-container"]}>
        <div className={styles["chat-message-answer-logo"]}>
          <AiChatLogoIcon />{" "}
        </div>
        {!answer && isLoading && (
          <span>
            <SpinnerIcon spin />
          </span>
        )}
        {answer && (
          <Markdown
            className={styles["chat-message-answer"]}
            options={{
              overrides: {
                a: {
                  component: "a",
                  props: {
                    target: "_blank",
                    onClick: handleLinkClick,
                  },
                },
              },
            }}
          >
            {answer}
          </Markdown>
        )}
        {!answer && !isLoading && !channelAnswerMessageId && (
          <div className={styles["chat-message-answer-no-answer"]}>
            No answer found
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ChatMessageItem);
