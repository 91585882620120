import React, { memo, useCallback, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  activeChannelAtom,
  activeChannelIdAtom,
  disableChatInputAtom,
} from "@src/components/ai_chatbot/atoms";
import useSendbirdHelper from "@src/components/ai_chatbot/hooks/use_sendbird_helper";
import { useChatMessageHandler } from "@src/components/ai_chatbot/hooks/use_chat_message_handler";
import styles from "@src/components/ai_chatbot/styles.module.scss";
import Chat from "@src/components/ai_chatbot/components/chat";
import ChatInput from "@src/components/ai_chatbot/components/chat_input";

export const ChatView = memo(() => {
  const { sdk } = useSendbirdHelper();
  const [disableChatInput, setDisableChatInput] =
    useRecoilState(disableChatInputAtom);

  const [activeChannel, setActiveChannel] = useRecoilState(activeChannelAtom);
  const activeChannelId = useRecoilValue(activeChannelIdAtom);

  const autoScrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    autoScrollRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [autoScrollRef]);

  const { handleMessageSend } = useChatMessageHandler();

  const getChannel = useCallback(async (channelId: string) => {
    try {
      const channel = await sdk.groupChannel.getChannel(channelId);
      setActiveChannel(channel);
    } catch (error) {
      console.error('Failed to fetch channel:', error);
      // Handle error appropriately - maybe show an error message to user
      setActiveChannel(undefined);
    }
  }, [sdk, setActiveChannel]);

  useEffect(() => {
    if (!activeChannel && activeChannelId) {
      getChannel(activeChannelId);
    }
  }, [activeChannelId, activeChannel, getChannel]);

  // set loader to false when ChatView is mounted
  useEffect(() => {
    setDisableChatInput(false);
  }, []);

  return (
    <>
      <div className={styles["chat-top-section"]}>
        <Chat scrollToBottom={scrollToBottom} ref={autoScrollRef} />
      </div>
      <div className={styles["chat-bottom-section"]}>
        <ChatInput
          handleMessageSend={handleMessageSend}
          loader={disableChatInput}
        />
      </div>
    </>
  );
});
