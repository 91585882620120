import React from 'react';

import classNames from 'classnames';

import styles from '../styles.module.scss';

interface IOnboardingPageProps {
  title: string;
  isHideBorder?: boolean;
  children: React.ReactNode;
}

const OnboardingPage = ({ title, children, isHideBorder }: IOnboardingPageProps) => {
  const classes = classNames(
    'background-white sign-in-main-panel m-l-auto m-r-auto',
    { 'border-none': isHideBorder }
  );
  
  return (
    <div className={ styles['onboarding-container'] }>
      <div className={ styles['onboarding-heading'] }>
        <h1 className="font-24 font-bold text-center">{ title }</h1>
      </div>
      <div className={ classes }>
        { children }
      </div>
    </div>
  );
};

export default OnboardingPage;
