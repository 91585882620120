import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import { useForm, useController } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useStandardCategories } from '@src/hooks/useStandardCategories';
import { createBusiness } from '@src/requests/businesses';

import BusinessField from '@src/components/common/form_fields/business_field';
import Form from '@src/components/ui/form';

import { addClientValidation, IAddClientFormValues } from './schema';

interface IAddBusinessFormProps {
  formId?: string;
  onSubmit?: (data: IAddBusinessFormValues) => void;
  onSuccess?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
}

interface IAddBusinessFormValues {
  standardCategory: number;
  legalName: string;
  displayName: string;
  serviceProviderBusinessId: number;
}

const AddBusinessForm: React.FC<IAddBusinessFormProps> = ({
  formId = 'addBusinessForm',
  onSubmit,
  setIsLoading,
  onSuccess,
}) => {
  const { categories, isLoading: isCategoriesLoading } = useStandardCategories();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddClientFormValues>({
    resolver:      yupResolver(addClientValidation),
    mode:          'onBlur',
    defaultValues: {
      serviceProviderBusinessId: Number(localStorage.getItem('accounting_firm_business_id')) || undefined,
    },

  });

  const businessIdController = useController({
    name: 'serviceProviderBusinessId',
    control,
  });

  const standardCategoryController = useController({
    name: 'standardCategory',
    control,
  });

  const handleFormSubmit = async (data: IAddBusinessFormValues) => {
    if (setIsLoading) {
      setIsLoading(true);
    }

    try {
      await createBusiness({
        business: {
          addBusinessAdvisor:        false,
          standardCategoryId:        data.standardCategory,
          name:                      data.legalName,
          displayName:               data.displayName,
          serviceProviderBusinessId: data.serviceProviderBusinessId,
        },
      });

      toastr.success(
        `Business "${data.displayName}" has been successfully added`,
        'Success',
      );

      if (onSubmit) {
        onSubmit(data);
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to create Business. Please try again.';
      toastr.error(errorMessage, 'Add Business Failed');
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  const categoryOptions = [
    { value: '', name: 'Select Industry', id: '' },
    ...categories.map((cat) => ({
      value: String(cat.id),
      name:  cat.name,
      id:    cat.id,
    })),
  ];

  return (
    <Form id={ formId } onSubmit={ handleSubmit(handleFormSubmit) }>

      {!businessIdController.field.value && (
        <BusinessField
          error={ errors.serviceProviderBusinessId?.message }
          label="Service Provider Business"
          { ...omit(businessIdController.field, 'ref') }
        />
      )}

      <Form.SelectField
        error={ errors.standardCategory?.message }
        label="Select Industry:"
        options={ categoryOptions }
        placeholder={ isCategoriesLoading ? 'Loading categories...' : 'Select Category' }
        { ...omit(standardCategoryController.field, 'ref') }
      />

      <Form.TextField
        error={ errors.legalName?.message }
        label="Legal Name:"
        placeholder="Enter Legal Name"
        { ...register('legalName') }
      />

      <Form.TextField
        error={ errors.displayName?.message }
        label="Display Name:"
        placeholder="Enter Display Name"
        { ...register('displayName') }
      />
    </Form>
  );
};

export default AddBusinessForm;
