export const ChatBotAtomKeys = {
  ActiveChannel: 'active_channel',
  ActiveChannelId: 'active_channel_id',
  ActiveThreadId: 'active_thread_id',
  Threads: 'threads',
  HistoryItems: 'history_items',
  ThreadLoadingStates: 'thread_loading_states',
  ConversationLoader: 'conversation_loader',
  ChatStatus: 'chat_status',
  DisableChatInput: 'disable_chat_input',
  ChatMessageLoader: 'chat_message_loader',
  FirstMessage: 'first_message',
};
