import React from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { Button } from '@src/components/ui_v2/buttons';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

interface AuditScanButtonProps {
  status?: AuditScanDetails['status'];
  onRunAudit: () => void;
}

const AuditScanButton = ({ status, onRunAudit }: AuditScanButtonProps) => {
  if (!status) {
    return null;
  }

  if (status === 'in_progress') {
    return <ProgressActivityLoader size="large" />;
  }

  return (
    <HoverTooltipWrapper
      content={ status === 'completed' ? (
        <div>
          Changes detected in the general ledger. Re-run the audit to view the updated data.
        </div>
      ) : null }
    >
      <Button
        size="compact"
        variant="outline"
        onClick={ onRunAudit }
      >
        {status === 'completed' ? 'Re-run Audit Scan' : 'Run Audit Scan'}
      </Button>
    </HoverTooltipWrapper>
  );
};

export default AuditScanButton;
