import React, { useState } from 'react';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import AddClientForm from './AddClientForm';

import styles from '../style.module.scss';

interface IAddClientPopupProps {
  className?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onDone?: () => void;
}

const AddClientPopup = ({
  className,
  isOpen: defaultIsOpen = false,
  onCancel,
  onDone,
}: IAddClientPopupProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(false);
    onCancel?.();
  };

  const handleSuccess = () => {
    setIsLoading(false);
    handleClose();
    onDone?.();
  };

  return (
    <>
      <Button bsColor="blue" className={ className } title="Add Client" onClick={ handleOpen } />
      <Modal
        dialogClassName="add-client-modal"
        region="first-modal-region"
        show={ isOpen }
        title="Add New Client"
        onCancel={ handleClose }
      >
        <Modal.Body>
          <AddClientForm setIsLoading={ setIsLoading } onSuccess={ handleSuccess } />
        </Modal.Body>
        <Modal.Footer>

          <CancelModalButton
            title="Cancel"
            onClick={ handleClose }
          />
          <Button
            className={ styles['new-blue-button'] }
            disabled={ isLoading }
            form="addClientForm"
            title="Add"
            type="submit"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddClientPopup;
