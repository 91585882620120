import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import classNames from "classnames";

import { Button } from "@src/components/ui_v2/buttons";

import {
  activeChannelAtom,
  activeChannelIdAtom,
  activeThreadIdAtom,
  firstMessageAtom,
} from "@src/components/ai_chatbot/atoms";
import styles from "@src/components/ai_chatbot/styles.module.scss";

interface IStartNewThreadProps {
  buttonText: string;
  prefixIcon?: React.ReactElement;
}

const StartNewThread = ({ buttonText, prefixIcon }: IStartNewThreadProps) => {
  const setActiveThreadId = useSetRecoilState(activeThreadIdAtom);
  const setActiveChannelId = useSetRecoilState(activeChannelIdAtom);
  const setActiveChannel = useSetRecoilState(activeChannelAtom);
  const setFirstMessage = useSetRecoilState(firstMessageAtom);

  const handleOnButtonClick = useCallback(() => {
    setActiveThreadId("");
    setActiveChannelId("");
    setActiveChannel(undefined);
    setFirstMessage({ message: "", messageSignature: "" });
  }, [
    setActiveThreadId,
    setActiveChannelId,
    setActiveChannel,
    setFirstMessage,
  ]);

  const buttonClass = classNames(styles["chat-btn"], "chat-thread-btn");

  return (
    <Button
      className={buttonClass}
      prefixIcon={prefixIcon}
      variant="link"
      onClick={handleOnButtonClick}
    >
      {buttonText}
    </Button>
  );
};

export default StartNewThread;
