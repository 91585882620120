import { TUUID } from "@src/types/common";

export interface IChatThreadSchema {
  id?: TUUID;
  userId?: number;
  summary: string;
  channelId?: string;
  providerId?: string;
  message?: string;
  timestamp?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IMessageSchema {
  id: TUUID;
  channelQuestionMessageId: number;
  question: string;
  answer: string;
  channelAnswerMessageId: number;
  timestamp?: string;
  messageSignature?: string;
  chatMessageStatus?: ChatMessageStatus;
}

export interface IHistoryItemsRequestState {
  data: IChatThreadSchema[];
  isLoading: boolean;
  error: string | null;
}

export interface IThreadsRequestState {
  data: {
    [threadId: TUUID]: IMessageSchema[];
  };
  isLoading: boolean;
  error: string | null;
}

export interface IMessageMetaData {
  channelQuestionMessageId: string;
  chatThreadId: string;
  messageId: string;
  messageSignature?: string;
  newThread?: boolean;
  isError?: boolean;
}
export interface IStartConversationProps {
  message?: string;
  channelId?: string;
  chatThreadId?: TUUID;
}

export interface IAiChatBot {
  conversationLoader: boolean;
  setConversationLoader: React.Dispatch<React.SetStateAction<boolean>>;
  startNewThread: (message: string) => Promise<void>;
}

export interface ICreateSessionTokenResponse {
  sessionToken: string;
}

export interface IMessageHistoryParams {
  chatThreadId?: TUUID;
}

export interface IGlobalMessageHistoryResponse {
  meta: {
    totalCount: number;
  };
  collection: IMessageSchema[];
}
export interface IThreadListParams {
  timestamp?: string;
  pageSize?: number;
}
export interface IGlobalThreadListResponse {
  meta: {
    totalCount: number;
  };
  collection: IChatThreadSchema[];
}

export interface ICreateChatThreadParams {
  userId: string;
  summary: string;
  channelId: string;
  providerId: string;
  message: string;
}

export enum ChatMessageStatus {
  Idle = "idle",
  QuestionSentToSendbird = "question_sent_to_sendbird",
  QuestionRegisteredInSendbird = "question_registered_in_sendbird",
  AnswerReceivedFromSendbird = "answer_received_from_sendbird",
  Error = "error",
}

export type TChatMessageLoaderAtom = Array<string>;

export interface IFirstMessageAtom {
  message: string;
  messageSignature: string;
}
