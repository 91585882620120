import React from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import Table from '@src/components/operational_dashboard/table/table';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { Filter } from './filter';
import { useNotesSideView } from '../components/sideview/notes_sideview';
import useAddBusinessModal from '../hooks/useAddBusinessModal';
import useAddClientModal from '../hooks/useAddClientModal';
import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

const BookgptList = () => {
  const view: TViewOperationalDashboard = window.Docyt.currentAdvisor.isBookgptAccountantUser() ? 'accountant' : 'business';
  const collection = useDashboardTableCollection({ view });
  const { control } = collection.FilterForm;

  const {
    Component: NotesSideView,
    props: notesSideViewProps,
    openWithValue: openNotesSideView,
  } = useNotesSideView();

  const handleOpenNotesSideView = (auditScan?: AuditScanDetails) => {
    if (auditScan) {
      openNotesSideView({
        auditScan,
      });
    }
  };

  const {
    Component: AddBusinessModalComponent,
    props: addBusinessModalProps,
  } = useAddBusinessModal({ onDone: () => {
    collection.query.refetch();
  } });

  const {
    Component: AddClientModalComponent,
    props: addClientModalProps,
  } = useAddClientModal({ onDone: () => {
    collection.query.refetch();
  } });

  return (
    <Section.Provider section={ collection?.section }>
      <NotesSideView
        { ...notesSideViewProps }
      />
      <div>
        <div className={ `row ${styles['bookgpt-list-header']}` }>
          <div className="col-md-6" />
          <div className="col-md-5 float-right"><Filter control={ control } /></div>
          <div className="col-md-1">
            { window.Docyt.currentAdvisor.isBookgptBusinessUser() && (
              <AddBusinessModalComponent
                { ...addBusinessModalProps }
                className={ `add-business-popup ${styles['add-business-btn']}` }
              />
            ) }
            { window.Docyt.currentAdvisor.isBookgptAccountantUser() && (
              <AddClientModalComponent
                { ...addClientModalProps }
                className={ `add-client-popup ${styles['add-client-btn']}` }
              />
            ) }
          </div>
        </div>
        <div className={ styles['bookgpt-list-container'] }>
          <TableSection className={ styles['bookgpt-list-section'] }>
            <Table
              infiniteQuery={ collection.query }
              records={ collection.records }
              sorting={ collection.sorting }
              view={ view }
              viewNotes={ handleOpenNotesSideView }
            />
          </TableSection>
        </div>
      </div>
    </Section.Provider>
  );
};

export default React.memo(BookgptList);
