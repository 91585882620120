import { useCallback, useEffect, useRef } from "react";
import { UserMessage } from "sendbird";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import toastr from "@lib/toastr";
import { camelizeKeys } from "@src/utils/transform_keys";

import {
  activeChannelAtom,
  activeThreadIdAtom,
  chatMessageLoaderAtom,
  threadsAtom,
} from "@src/components/ai_chatbot/atoms";
import {
  ChatMessageStatus,
  IMessageMetaData,
} from "@src/components/ai_chatbot/types";
import { chatChannelCreationMessage } from "@src/components/ai_chatbot/constants";
import {
  getDefaultDataProperty,
  findWithIndex,
} from "@src/components/ai_chatbot/utils";

export const useChatMessageHandler = () => {
  const [activeThreadId, setActiveThreadId] =
    useRecoilState(activeThreadIdAtom);
  const [threads, setThreads] = useRecoilState(threadsAtom);
  const activeChannel = useRecoilValue(activeChannelAtom);
  const setChatMessageLoader = useSetRecoilState(chatMessageLoaderAtom);

  // Create a ref to hold the current threads data
  const threadsRef = useRef(threads.data);
  const activeThreadIdRef = useRef(activeThreadId);
  const activeChannelRef = useRef(activeChannel);

  /**
   * Update the ref whenever threads changes.
   * This technique is to prevent the useEffect responsible
   * for attaching the channel handler from being triggered
   * when the message is received.
   *
   * If it is triggered then the channel handler will be removed
   * before the message is received, and by the time the channel handler
   * is re-attached, the message will be lost.
   */
  useEffect(() => {
    threadsRef.current = threads.data;
  }, [threads.data]);

  useEffect(() => {
    activeThreadIdRef.current = activeThreadId;
  }, [activeThreadId]);

  useEffect(() => {
    activeChannelRef.current = activeChannel;
  }, [activeChannel]);

  const onMessageReceived: SendBird.ChannelHandler["onMessageReceived"] =
    useCallback(
      (_channel: any, message: UserMessage) => {
        const parsedMetaData = message.data
          ? (camelizeKeys(
              JSON.parse(message.data.replace(/'/g, '"'))
            ) as IMessageMetaData)
          : undefined;

        if (parsedMetaData?.chatThreadId && !activeThreadIdRef.current) {
          setActiveThreadId(parsedMetaData.chatThreadId);
        } else if (
          activeThreadIdRef.current &&
          activeThreadIdRef.current !== parsedMetaData?.chatThreadId
        ) {
          return;
        }

        if (!parsedMetaData?.channelQuestionMessageId) {
          setThreads((prevThreads) => {
            const prevMessages =
              prevThreads.data[activeThreadIdRef.current] || [];
            let updatedMessages = [...prevMessages];

            // Update pending message with confirmed message
            const index = updatedMessages.findIndex(
              (item) =>
                item.messageSignature === parsedMetaData?.messageSignature
            );

            if (index !== -1) {
              if (parsedMetaData?.isError) {
                updatedMessages[index] = {
                  ...updatedMessages[index],
                  answer: String(message.message),
                  chatMessageStatus: ChatMessageStatus.Error,
                };
              } else {
                updatedMessages[index] = {
                  ...updatedMessages[index],
                  channelQuestionMessageId: message.messageId,
                  question: String(message.message),
                  timestamp: String(message.createdAt),
                  chatMessageStatus:
                    ChatMessageStatus.QuestionRegisteredInSendbird,
                };
              }
            }

            return {
              ...prevThreads,
              data: {
                ...prevThreads.data,
                [activeThreadIdRef.current]: updatedMessages,
              },
            };
          });
        } else if (parsedMetaData?.channelQuestionMessageId) {
          setThreads((prevThreads) => {
            const prevMessages =
              prevThreads.data[activeThreadIdRef.current] || [];
            let updatedMessages = [...prevMessages];

            // Update answer for existing message
            const { element: currentMessage, index } = findWithIndex(
              updatedMessages,
              (item) =>
                item.channelQuestionMessageId ===
                Number(parsedMetaData.channelQuestionMessageId)
            );

            if (index !== -1 && !!currentMessage) {
              updatedMessages[index] = {
                ...currentMessage,
                id: String(parsedMetaData?.messageId),
                answer: String(message.message),
                channelAnswerMessageId: message.messageId,
                timestamp: String(message.createdAt),
                chatMessageStatus: ChatMessageStatus.AnswerReceivedFromSendbird,
              };
            }
            return {
              ...prevThreads,
              data: {
                ...prevThreads.data,
                [activeThreadIdRef.current]: updatedMessages,
              },
            };
          });

          setChatMessageLoader((currentlyLoading) =>
            currentlyLoading.filter(
              (signature) => signature !== parsedMetaData.messageSignature
            )
          );
        }
      },
      [setActiveThreadId, setThreads, setChatMessageLoader]
    );

  const handleMessageSend = useCallback(
    async (message: string) => {
      const messageSignature = crypto.randomUUID();
      try {
        if (activeChannelRef.current) {
          setChatMessageLoader((prev) => [...prev, messageSignature]);

          setThreads((prevThreads) => {
            const prevMessages = prevThreads.data[activeThreadIdRef.current];
            return {
              ...prevThreads,
              data: {
                ...prevThreads.data,
                [activeThreadIdRef.current]: [
                  {
                    id: "",
                    channelQuestionMessageId: 0,
                    question: message,
                    answer: "",
                    channelAnswerMessageId: 0,
                    messageSignature,
                  },
                  ...prevMessages,
                ],
              },
            };
          });

          const data = getDefaultDataProperty(
            activeThreadIdRef.current,
            messageSignature
          );
          activeChannelRef.current.sendUserMessage({ message, data });
        }
      } catch (error) {
        console.error("[ChatView] handleMessageSend error:", error);
        toastr.error(
          chatChannelCreationMessage.errorSendingMessage.message,
          chatChannelCreationMessage.errorSendingMessage.title
        );
      }
    },
    [setThreads, setChatMessageLoader]
  );

  return {
    onMessageReceived,
    handleMessageSend,
  };
};
