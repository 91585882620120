import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { activeThreadIdAtom } from "@src/components/ai_chatbot/atoms";
import { ChatView } from "@src/components/ai_chatbot/components/chat_view";
import WelcomeView from "@src/components/ai_chatbot/components/welcome_view";
import styles from "@src/components/ai_chatbot/styles.module.scss";

const ChatContainer = () => {
  const activeThreadId = useRecoilValue(activeThreadIdAtom);

  return (
    <div className={styles["chat-message-container"]}>
      {!!activeThreadId ? <ChatView /> : <WelcomeView />}
    </div>
  );
};

export default memo(ChatContainer);
