import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  createUserAccount,
  getDefaultAccountingFirm,
  ICreateUserAccountParams,
  resendEmail,
} from '@src/requests/signup';
import { IBusiness } from '@src/types/businesses';

const useResendEmail = () => {
  return useMutation<void, Error, string>(resendEmail);
};

const useCreateUserAccount = () => {
  return useMutation<void, Error, ICreateUserAccountParams>(createUserAccount);
};

const useGetDefaultAccountingFirm = () => {
  return useQuery<IBusiness, Error>(
    [QueryKey.defaultAccountingFirm],
    () => getDefaultAccountingFirm(),
  );
};

export { useResendEmail, useCreateUserAccount, useGetDefaultAccountingFirm };
