import React from 'react';

import Root from '@src/components/root';

import ConnectLedgerView from './connect_ledger/connect_ledger_view';

const ConnectLedgerPage = () => {
  return (
    <Root>
      <ConnectLedgerView />
    </Root>
  );
};

export default React.memo(ConnectLedgerPage);